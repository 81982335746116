/**
 * returns a promise which resolves after the given number of milliseconds
 */
export function waitFor(time = 0) {
  return new Promise(resolve => setTimeout(resolve, time));
}

/**
 * takes a condition function and options. if the condition Fn returns a truthy value resolves, otherwise
 * waits for `timeout` ms and runs it again. When maxTryCount is reached it rejects
 * */
export function checkCondition(conditionFn, {timeout = 100, maxTryCount = 10} = {}) {
  return new Promise((resolve, reject) => {
    let tryCount = 0;
    const doCheck = async () => {
      const result = await conditionFn();
      if (result) {
        resolve(result);
      } else if (tryCount < maxTryCount) {
        tryCount++;
        setTimeout(doCheck, timeout);
      } else {
        reject(`Exceeded ${maxTryCount} attempts for conditionFn ${conditionFn}`);
      }
    };

    doCheck();
  });
}
