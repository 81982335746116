import {defineStore} from 'pinia';

export const usePricingStore = defineStore('pricingStore', {
  state: () => ({
    promotions: []
  }),

  actions: {
    async getPromotions() {
      this.promotions = await usePricingApi().getPromotionPricing();
    }
  }
});
