
const BLOCKED_BY_ERROR_MESSAGE = [
    'error: network error',
    'request aborted',
    'timeout exceeded',
    'error: timeout of 14000ms exceeded',
    'the process cannot access the file because it is being used by another process.',
    'unspecified error.',
    `can't redefine non-configurable property "useragent"`,
    'access is denied.',
    'timeout of 0ms exceeded',
    `cannot read property 'closingels' of undefined`,
    'unexpected end of input',
    'the device is not ready.',
    'the operation was aborted.',
    'javacalljswithargs is not defined',
    'javacalljs is not defined',
    `cannot set property 'status' of undefined`,
    'vid_mate_check is not defined',
    'could not complete the operation due to error 80073003.',
    'the play() request was interrupted by a call to pause().',
    'the remote procedure call failed.',
    'play() can only be initiated by a user gesture.',
    `this.mediael.webkitenterfullscreen is not a function. (in 'this.mediael.webkitenterfullscreen()', 'this.mediael.webkitenterfullscreen' is undefined)`,
    'the request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.'
];

const BLOCKED_BY_STACK = [
    '/app/assets/photosdk',
    'safari-extension://',
    'user-script:',
    'System.ServiceModel.CommunicationObjectFaultedException',
    'The system cannot find the file specified',
    'Not enough storage is available to complete this operation.',
    'vc_request_action',
    'The remote procedure call failed.',
    `Object doesn't support property or method 'attachEvent'`,
    'Could not complete the operation due to error 8007ffff.',
    'fullscreen error',
    'TypeError: Illegal invocation'
];

const alertTracker = new Map();

function shouldPostError(error) {
  const isBlockedByStack = error.stack && BLOCKED_BY_STACK.some(m => error.stack.includes(m));
  const isBlockedByErrorMessage = error.message && error.message.toLowerCase && BLOCKED_BY_ERROR_MESSAGE.includes(error.message.trim().toLowerCase());
  const statusCode = (error.response?.status || error.statusCode);
  const isHttpError = statusCode >= 400 && statusCode < 500;
  const isCancelledPromise = error.isCancelled; //issued by async queue and uploader (upload cancel)
  const isFacebookDialogCancel = error && error.error_code === 4201; //facebook cancelled dialog flow
  const isCSPRelated = error && error.isTrusted; //script error
  const isEmptyObject = !(error instanceof Error) && !Object.keys(error).length; //empty object

  return !isBlockedByStack &&
    !isHttpError &&
    !isCancelledPromise &&
    !isBlockedByErrorMessage &&
    !isFacebookDialogCancel &&
    !isCSPRelated &&
    !isEmptyObject;
}

async function postToWatchtower({appName, payload, user}) {
  const runtimeConfig = useRuntimeConfig().public;

  try {
    const errorMessage = `*Error Message*: ${payload.message}`;
    const srcElem = payload.srcElem
      ? `*Element*: \`${payload.srcElem.outerHTML
        .slice(0, payload.srcElem.outerHTML.indexOf('>') + 1)
        .replace(/&/, '&amp;')
        .replace(/</, '&lt;')
        .replace(/>/, '&gt;')}\``
      : '';
    const info = payload.info
      ? `*Info*: ${payload.info}`
      : '';
    const location = (() => {
      try {
       return useRoute().fullPath;
      } catch (e) {
        if (process.client) {
          return window?.location.href;
        }
      }
    })();
    const locationStr = `*Location*: ${runtimeConfig.appName || '<no-app-name>'}: ${location}`;
    const userStr = user ? `*User*: \`${user.name} &lt;${user.id}&gt;\`` : '';

    const postAlert = (occurrenceCount = 1) => {
      const occurrenceMsg = occurrenceCount > 1
        ? `\n*Occurred ${occurrenceCount} times in the last 10 minutes.*`
        : '';

      return $fetch('/app-error', {
        method: 'POST',
        baseURL: runtimeConfig.watchtowerHost,
        body: {
          appName,
          title: 'Error',
          appVersion: payload.appVersion,
          userAgent: payload.userAgent,
          slackChannel: runtimeConfig.watchtowerChannel,
          message: `${errorMessage}\n${userStr}\n${locationStr}\n${srcElem}\n${info}\n*Capture Hook*: \`${payload.hook}\`${occurrenceMsg}`,
          error: payload.stack
        }
      });
    };

    const cleanUpTracker = () => {
      const postRequests = [];

      for (const [key, tracker] of alertTracker[Symbol.iterator]()) {
        if (tracker.firstOccurred + (30 * 10 * 1000) < Date.now() && tracker.occurrenceCount > 1) {
          postRequests.push(postAlert(tracker.occurrenceCount));
          alertTracker.delete(key);
        }
      }

      return Promise.all(postRequests);
    };

    if (alertTracker.has(payload.stack)) {
      const trackedAlert = alertTracker.get(payload.stack);
      trackedAlert.occurrenceCount++;
    } else {
      alertTracker.set(payload.stack, {
        firstOccurred: Date.now(),
        occurrenceCount: 1
      });

      await postAlert();
    }

    await cleanUpTracker();

  } catch (e) {
    throw Error(`Failed to post to watchtower. ${e}`);
  }
}

export function useErrorPost() {
  return {
    report({appName = 'core-web-ui', error, instance, info}) {
      const runtimeConfig = useRuntimeConfig().public;

      if (runtimeConfig.enableClientErrorCatch) {
        if (!shouldPostError(error)) {
          return;
        }

        const payload = {
          message: error.message || JSON.stringify(error), //note: when payload (error thrown) is a string or not a real Error object
          stack: error.stack,
          appVersion: runtimeConfig.appVersion,
          userAgent: process.client && navigator.userAgent
        };

        if (instance) {
          payload.srcElem = instance.$el;
        }
        if (info) {
          payload.info = info;
        }

        //attempt to get the user
        const user = (() => {
          try {
            return useSessionStore().currentUser;
          } catch (e) {
            return null;
          }
        })();

        postToWatchtower({appName, payload, user});
      }
    }
  }
}
