export function notificationMapper(item) {
  return new Notification(item);
}

class Notification {

  constructor(item) {
    this._ = item;
    this.id = item.id;
    this.dateCreatedRelative = useTimeAgo(item.attributes.created_at);
    this.dateUpdated = item.attributes.updated_at;
    this.primaryType = item.attributes.primary_type;
    this.subtype = item.attributes.subtype;
    this.status = item.attributes.status;
    this.icon = 'i-ri-star-line'; //default

    try {
      this[this.subtype]();
    } catch (e) {
      //noop
    }
  }

  [NOTIFICATION_SUBTYPES.friendRequest]() {
    this.sendingUser = {
      name: html2text(this._.attributes.body.sender_name),
      id: this._.attributes.body.sender_id,
      profileClassName: getProfileClassNameFromUUID(this._.attributes.body.sender_id),
      slug: this._.attributes.body.sender_slug,
      avatarImage: this._.attributes.body.sender_avatar_url
    };
    this.icon = 'i-ri-user-add-line';
    this.message = `<strong>${this.sendingUser.name}</strong> sent you a connection request.`;
  }

  [NOTIFICATION_SUBTYPES.friendRequestAccepted]() {
    this.sendingUser = {
      name: html2text(this._.attributes.body.sender_name),
      profileClassName: getProfileClassNameFromUUID(this._.attributes.body.sender_id),
      id: this._.attributes.body.sender_id,
      slug: this._.attributes.body.sender_slug,
      avatarImage: this._.attributes.body.sender_avatar_url
    };
    this.icon = 'i-ri-user-add-line';
    this.message = `<strong>${this.sendingUser.name}</strong> accepted your connection request.`;
  }

  [NOTIFICATION_SUBTYPES.madeAccountManager]() {
    this.sendingUser = {
      name: html2text(this._.attributes.body.sender_name),
      id: this._.attributes.body.sender_id,
      profileClassName: getProfileClassNameFromUUID(this._.attributes.body.sender_id),
      slug: this._.attributes.body.sender_slug,
      avatarImage: this._.attributes.body.sender_avatar_url
    };
    this.icon = 'i-ri-user-shared-line';
    this.message = `<strong>${this.sendingUser.name}</strong> made you their account manager.`;
  }

  [NOTIFICATION_SUBTYPES.uploadComplete]() {
    this.deviceName = html2text(this._.attributes.body.device_name);
    this.sendingUser = {
      name: this._.attributes.body.sender_name,
      avatarImage: this._.attributes.body.sender_avatar_url
    };
    this.icon = COMMON_ICONS.upload;
    this.message = `New files were uploaded to your account from <strong>${this.deviceName}.</strong>`;
  }

  [NOTIFICATION_SUBTYPES.uploadReminder]() {
    this.sendingUser = {
      name: this._.attributes.body.sender_name,
      avatarImage: this._.attributes.body.sender_avatar_url
    };
    this.icon = COMMON_ICONS.upload;
    this.message = `<strong>It's been a while</strong> since you uploaded to FOREVER®. <strong>Upload now</strong> to keep your memories safe!`;
  }

  [NOTIFICATION_SUBTYPES.friendAlbumFilesAdded]() {
    this.sendingUser = {
      name: html2text(this._.attributes.body.album_owner_name),
      id: this._.attributes.body.album_owner_id,
      profileClassName: getProfileClassNameFromUUID(this._.attributes.body.album_owner_id),
      slug: this._.attributes.body.album_owner_slug,
      avatarImage: this._.attributes.body.album_owner_avatar_url
    };
    this.contextAlbum = {
      slug: this._.attributes.body.album_slug,
      name: html2text(this._.attributes.body.album_name),
      image: this._.attributes.body.album_cover_url,
      shareToken: this._.attributes.body.album_share_token
    };
    this.since = this._.attributes.body.files_added_at;
    this.icon = COMMON_ICONS.album;
    this.message = `<strong>${this.sendingUser.name}</strong> added files to their album <strong>${this.contextAlbum.name}</strong>.`;
  }

  [NOTIFICATION_SUBTYPES.orderShipped]() {
    this.sendingUser = {
      name: this._.attributes.body.sender_name,
      avatarImage: this._.attributes.body.sender_avatar_url
    };
    this.shippingMethod = this._.attributes.body.shipping_method;
    this.trackingNumber = this._.attributes.body.tracking_number;
    this.url = this._.attributes.body.url;
    this.icon = 'i-ri-truck-line';
    this.message = `Your order has shipped via <strong>${this.shippingMethod}</strong> with tracking number <strong>${this.trackingNumber}</strong>`;
  }

  [NOTIFICATION_SUBTYPES.exifDownloadReady]() {
    this.sendingUser = {
      name: this._.attributes.body.sender_name,
      avatarImage: this._.attributes.body.sender_avatar_url
    };
    this.fileCount = this._.attributes.body.file_count;
    this.downloadId = this._.attributes.body.download_id;
    this.containerType = this._.attributes.body.container_type;
    this.containerName = html2text(this._.attributes.body.container_name);
    this.icon = COMMON_ICONS.download;
    if (this.containerName) {
      this.message = `Your download of <strong>${this.fileCount} file(s) with metadata from the ${this.containerType.toLowerCase()} ${this.containerName}</strong> is ready. Click here to download.`;
    } else {
      this.message = `Your download of <strong>${this.fileCount} file(s) with metadata</strong> is ready. Click here to download.`;
    }

  }
}
