<template>
  <div>
    <core-modal-header>
      <span v-html="heading" />
    </core-modal-header>

    <core-modal-body>
      <p v-if="message" v-html="message" />
    </core-modal-body>

    <core-modal-footer>
      <u-button color="charcoal" variant="soft" @click="modal.close()">Cancel</u-button>
      <u-button :color="color" @click="confirm" :loading="isSaving">Confirm</u-button>
    </core-modal-footer>
  </div>
</template>

<script setup>
  const props = defineProps({
    color: String,
    heading: String,
    message: String,
    confirmMethod: Function,
    modal: Object
  });

  const emit = defineEmits(['on-confirm']);

  const isSaving = ref(false);
  async function confirm() {
    try {
      isSaving.value = true;
      await props.confirmMethod();
      emit('on-confirm');
      props.modal.close();
    } finally {
      isSaving.value = false;
    }
  }
</script>
