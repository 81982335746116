import { default as indexP7nSA0pfDBMeta } from "/tmp/build_4079e1f5/pages/connections/index.vue?macro=true";
import { default as indexergUHCSWvbMeta } from "/tmp/build_4079e1f5/pages/delete-bin/index.vue?macro=true";
import { default as _91share_token_93Ta6Ep965cqMeta } from "/tmp/build_4079e1f5/pages/external/embed/[user_id]/[file_id]/[share_token].vue?macro=true";
import { default as _91file_id_93nUL0sQftNqMeta } from "/tmp/build_4079e1f5/pages/gallery/[file_id].vue?macro=true";
import { default as galleryJh14WtIZRPMeta } from "/tmp/build_4079e1f5/pages/gallery.vue?macro=true";
import { default as indexSKzRHq6TLKMeta } from "/tmp/build_4079e1f5/pages/groups/index.vue?macro=true";
import { default as _91_91id_93_93yiOdC3dNDYMeta } from "/tmp/build_4079e1f5/pages/impersonate/[[id]].vue?macro=true";
import { default as log_45inn5ONskX7XGMeta } from "/tmp/build_4079e1f5/pages/log-in.vue?macro=true";
import { default as log_45outDAHsrRu3ZGMeta } from "/tmp/build_4079e1f5/pages/log-out.vue?macro=true";
import { default as indexnWBqD3xg00Meta } from "/tmp/build_4079e1f5/pages/notifications/index.vue?macro=true";
import { default as projectsaOVfkVgQa4Meta } from "/tmp/build_4079e1f5/pages/projects.vue?macro=true";
import { default as _91file_id_93u0tMEcZrNhMeta } from "/tmp/build_4079e1f5/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_93RGHcS3vIOyMeta } from "/tmp/build_4079e1f5/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]].vue?macro=true";
import { default as indexssYT6GwweNMeta } from "/tmp/build_4079e1f5/pages/users/[user_id_or_slug]/albums/index.vue?macro=true";
import { default as visited6cRVqgBz3JMeta } from "/tmp/build_4079e1f5/pages/users/[user_id_or_slug]/albums/visited.vue?macro=true";
import { default as _91file_id_93SZRUdCqc4nMeta } from "/tmp/build_4079e1f5/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_93LOMhtTuCE3Meta } from "/tmp/build_4079e1f5/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]].vue?macro=true";
import { default as _91_91share_token_93_93fQoUWpfsYgMeta } from "/tmp/build_4079e1f5/pages/users/[user_id_or_slug]/files/[file_id]/[[share_token]].vue?macro=true";
import { default as index63was6SXmVMeta } from "/tmp/build_4079e1f5/pages/users/[user_id_or_slug]/index.vue?macro=true";
import { default as _91file_id_93iFKmQXudeHMeta } from "/tmp/build_4079e1f5/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_93dxwK96H1hPMeta } from "/tmp/build_4079e1f5/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]].vue?macro=true";
import { default as indexRfxsNQlJf7Meta } from "/tmp/build_4079e1f5/pages/users/[user_id_or_slug]/people/index.vue?macro=true";
import { default as _91file_id_93O3eQANgHnjMeta } from "/tmp/build_4079e1f5/pages/users/[user_id_or_slug]/print-projects/[file_id].vue?macro=true";
import { default as print_45projectsDxw7GqpA0kMeta } from "/tmp/build_4079e1f5/pages/users/[user_id_or_slug]/print-projects.vue?macro=true";
import { default as _91file_id_93ZJnBRPBdZFMeta } from "/tmp/build_4079e1f5/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_93qfEbWNKUsEMeta } from "/tmp/build_4079e1f5/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]].vue?macro=true";
import { default as indexmVFr1l8uF5Meta } from "/tmp/build_4079e1f5/pages/users/[user_id_or_slug]/tags/index.vue?macro=true";
import { default as welcomerPG8nPVlgyMeta } from "/tmp/build_4079e1f5/pages/welcome.vue?macro=true";
import { default as component_45stubFt9z7mK7grMeta } from "/tmp/build_4079e1f5/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubFt9z7mK7gr } from "/tmp/build_4079e1f5/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "connections",
    path: "/connections",
    meta: indexP7nSA0pfDBMeta || {},
    component: () => import("/tmp/build_4079e1f5/pages/connections/index.vue")
  },
  {
    name: "delete-bin",
    path: "/delete-bin",
    meta: indexergUHCSWvbMeta || {},
    component: () => import("/tmp/build_4079e1f5/pages/delete-bin/index.vue")
  },
  {
    name: "external-embed-user_id-file_id-share_token",
    path: "/external/embed/:user_id()/:file_id()/:share_token()",
    component: () => import("/tmp/build_4079e1f5/pages/external/embed/[user_id]/[file_id]/[share_token].vue")
  },
  {
    name: "gallery",
    path: "/gallery",
    meta: galleryJh14WtIZRPMeta || {},
    component: () => import("/tmp/build_4079e1f5/pages/gallery.vue"),
    children: [
  {
    name: "gallery-file_id",
    path: ":file_id()",
    component: () => import("/tmp/build_4079e1f5/pages/gallery/[file_id].vue")
  }
]
  },
  {
    name: "groups",
    path: "/groups",
    meta: indexSKzRHq6TLKMeta || {},
    component: () => import("/tmp/build_4079e1f5/pages/groups/index.vue")
  },
  {
    name: "impersonate-id",
    path: "/impersonate/:id?",
    component: () => import("/tmp/build_4079e1f5/pages/impersonate/[[id]].vue")
  },
  {
    name: "log-in",
    path: "/log-in",
    component: () => import("/tmp/build_4079e1f5/pages/log-in.vue")
  },
  {
    name: "log-out",
    path: "/log-out",
    component: () => import("/tmp/build_4079e1f5/pages/log-out.vue")
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: indexnWBqD3xg00Meta || {},
    component: () => import("/tmp/build_4079e1f5/pages/notifications/index.vue")
  },
  {
    name: "projects",
    path: "/projects",
    meta: projectsaOVfkVgQa4Meta || {},
    component: () => import("/tmp/build_4079e1f5/pages/projects.vue")
  },
  {
    name: "users-user_id_or_slug-albums-album_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/albums/:album_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_4079e1f5/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-albums-album_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_4079e1f5/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-albums",
    path: "/users/:user_id_or_slug()/albums",
    meta: indexssYT6GwweNMeta || {},
    component: () => import("/tmp/build_4079e1f5/pages/users/[user_id_or_slug]/albums/index.vue")
  },
  {
    name: "users-user_id_or_slug-albums-visited",
    path: "/users/:user_id_or_slug()/albums/visited",
    meta: visited6cRVqgBz3JMeta || {},
    component: () => import("/tmp/build_4079e1f5/pages/users/[user_id_or_slug]/albums/visited.vue")
  },
  {
    name: "users-user_id_or_slug-containers-container_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/containers/:container_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_4079e1f5/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-containers-container_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_4079e1f5/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-files-file_id-share_token",
    path: "/users/:user_id_or_slug()/files/:file_id()/:share_token?",
    component: () => import("/tmp/build_4079e1f5/pages/users/[user_id_or_slug]/files/[file_id]/[[share_token]].vue")
  },
  {
    name: "users-user_id_or_slug",
    path: "/users/:user_id_or_slug()",
    component: () => import("/tmp/build_4079e1f5/pages/users/[user_id_or_slug]/index.vue")
  },
  {
    name: "users-user_id_or_slug-people-person_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/people/:person_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_4079e1f5/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-people-person_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_4079e1f5/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-people",
    path: "/users/:user_id_or_slug()/people",
    meta: indexRfxsNQlJf7Meta || {},
    component: () => import("/tmp/build_4079e1f5/pages/users/[user_id_or_slug]/people/index.vue")
  },
  {
    name: "users-user_id_or_slug-print-projects",
    path: "/users/:user_id_or_slug()/print-projects",
    meta: print_45projectsDxw7GqpA0kMeta || {},
    component: () => import("/tmp/build_4079e1f5/pages/users/[user_id_or_slug]/print-projects.vue"),
    children: [
  {
    name: "users-user_id_or_slug-print-projects-file_id",
    path: ":file_id()",
    component: () => import("/tmp/build_4079e1f5/pages/users/[user_id_or_slug]/print-projects/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-tags-tag_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/tags/:tag_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_4079e1f5/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-tags-tag_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_4079e1f5/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-tags",
    path: "/users/:user_id_or_slug()/tags",
    meta: indexmVFr1l8uF5Meta || {},
    component: () => import("/tmp/build_4079e1f5/pages/users/[user_id_or_slug]/tags/index.vue")
  },
  {
    name: "welcome",
    path: "/welcome",
    meta: welcomerPG8nPVlgyMeta || {},
    component: () => import("/tmp/build_4079e1f5/pages/welcome.vue")
  },
  {
    name: component_45stubFt9z7mK7grMeta?.name,
    path: "/",
    component: component_45stubFt9z7mK7gr
  },
  {
    name: component_45stubFt9z7mK7grMeta?.name,
    path: "/library",
    component: component_45stubFt9z7mK7gr
  },
  {
    name: component_45stubFt9z7mK7grMeta?.name,
    path: "/static/images/forever-album-no-cover.png",
    component: component_45stubFt9z7mK7gr
  }
]