import {setPageLayout} from '#app';
import {AUTH_COOKIE_NAME} from "~/conf/app.js";

export default defineNuxtRouteMiddleware(to => {
  const session = useSessionStore();
  const isAuthenticated = Boolean(useCookie(AUTH_COOKIE_NAME).value);

  if (!isAuthenticated || to.path === '/log-out') {
    setPageLayout('noauth');
  }

  if (!isAuthenticated && to.path === '/log-in') {
    setPageLayout(false);
  }

  //when not authenticated and page requires auth
  else if (!isAuthenticated && to.meta.requiresAuth) { //todo: add clear all store data
    return navigateTo({path: '/log-in', query: {redirectTo: to.path}});
  }

  //when authenticated and user hits log in
  else if (isAuthenticated && to.name === 'log-in') {
    return navigateTo(session.currentUser?.landing_path)
  }


  else if (isAuthenticated && session.currentUser?.account_type === USER_ACCOUNT_TYPES.unspecified.value && to.name !== 'welcome') {
    return navigateTo({path: '/welcome', query: {redirectTo: to.path}});
  }


  //todo move this out of here
  if (to.path.includes('external/embed')) {
    setPageLayout(false);

  }

});
