import {defineStore} from 'pinia';
import {CURRENT_USER_PREFERENCES} from '~/constants/preferences.js';

const apiPaths = {
  currentUserPreferences: '/current_user/preferences', //get, put
}

function parsePreferences(prefs, stringify) {
  const p = Object.assign({}, prefs);
  const method = stringify ? 'stringify' : 'parse';
  for (const key in p) {
    if (p.hasOwnProperty(key)) {
      try {
        p[key] = (stringify && typeof p[key] === 'string') ? p[key] : JSON[method](p[key]);
      } catch (e) {
        //do nothing, we just want to not throw up errors by not being able to parse
      }
    }
  }
  return p;
}


export const usePreferencesStore = defineStore('preferences', {
  state: () => ({
    currentUserPreferences: {},
    notificationPreferences: {}
  }),

  actions: {
    async getCurrentUserPreferences() {
      const data = await useMarsApiV3().fetch(apiPaths.currentUserPreferences);
      this.currentUserPreferences = parsePreferences(data.preferences);

      return this.currentUserPreferences;
    },

    async updateCurrentUserPreferences(prefs) {
      const data = await useMarsApiV3().fetch(
        apiPaths.currentUserPreferences,
        {
          method: 'PUT',
          body: {preferences: parsePreferences(prefs, true)}
        }
      );

      this.currentUserPreferences = parsePreferences(data.preferences);

      return this.currentUserPreferences;
    },

    async clearOnboardingSettings() {

      const onboardingPreferences = Object.keys(CURRENT_USER_PREFERENCES).filter(key => key.startsWith('onb')).map(key => CURRENT_USER_PREFERENCES[key]);

      onboardingPreferences.forEach(p => {
        this.currentUserPreferences[p] = null;
      });
      const data = await useMarsApiV3().fetch(
        apiPaths.currentUserPreferences,
        {
          method: 'PUT',
          body: {preferences: parsePreferences(this.currentUserPreferences, true)}
        }
      );
      this.currentUserPreferences = parsePreferences(data.preferences);
    }
  }
})
