<template>
  <div>
    <core-modal-header>Updates Ready! 🚀</core-modal-header>

    <core-modal-body>
      <p>We're ready to update to the latest version of FOREVER. All we need to do is refresh your browser. Recent changes you've made have been saved.</p>
    </core-modal-body>

    <core-modal-footer>
      <u-button variant="soft" color="charcoal" @click="modal.close()">Do This Later</u-button>
      <u-button @click="confirm" :loading="isRefreshing">Refresh Now</u-button>
    </core-modal-footer>
  </div>
</template>

<script setup>
  const props = defineProps({
    modal: Object
  });

  const isRefreshing = ref(false);
  async function confirm() {
    isRefreshing.value = true;
    window.location.reload(true);
  }
</script>
