import {getActivePinia, acceptHMRUpdate, defineStore} from 'pinia';
import {AUTH_COOKIE_NAME, AUTH_ON_LOGOUT_REDIRECT} from '../conf/app.js';

export const useSessionStore = defineStore('session', {

  state: () => ({
    currentUser: null,
    userStoreInfo: null,
    deleteBinSummary: null
  }),

  actions: {
    async logIn({email, password}) {
      const user = await useSessionApi().logIn({email, password});
      this.currentUser = mapCurrentUser(user);
      this.setAuthCookie(this.currentUser.auth_token)
    },

    async logOut() {
      useCookie(AUTH_COOKIE_NAME, {
        expires: (() => {
          const date = new Date();
          date.setYear(1970);

          return date;
        })(),
        domain: getDomain(),
        path: '/'
      }).value = '';

      this.resetAllStores();
    },

    setAuthCookie(token) {
      useCookie(AUTH_COOKIE_NAME, {
        expires: (() => {
          const date = new Date();
          date.setYear(date.getFullYear() + 1);

          return date;
        })(),
        domain: getDomain(),
        path: '/'
      }).value = token;
    },

    resetAllStores() {
      //todo revisit later, fra-gi-le: https://github.com/vuejs/pinia/discussions/1859
      getActivePinia()._s.forEach(item => item.$reset());
    },

    async getCurrentUser() {
      let user;
      try {
        user = await useSessionApi().getCurrentUser();
        this.currentUser = mapCurrentUser(user);
      } catch (e) {

        //todo: revisit this to account for other errors that should not log user out (network, etc)
        if (e.message) {
          await this.logOut();
          await useRouter().push({path: AUTH_ON_LOGOUT_REDIRECT});
        }
      }

      return user;
    },

    async updateCurrentUser(user) {
      const updatedUser = await useSessionApi().updateCurrentUser(user);
      this.currentUser = Object.assign(this.currentUser, mapCurrentUser(updatedUser));
      return this.currentUser;
    },

    async getUserStoreInfo() {
      const info = await useSessionApi().getUserStoreInfo();
      this.userStoreInfo = info;
      return info;
    },

    async getDeleteBinSummary() {
      const summary = await useSessionApi().getDeleteBinSummary();
      this.deleteBinSummary = summary;
      return summary;
    },

    async toggleImpersonate({id}) {
      const user = await useSessionApi()[id ? 'startImpersonate' : 'stopImpersonate']({id});
      this.resetAllStores();
      this.currentUser = mapCurrentUser(user);
      this.setAuthCookie(this.currentUser.auth_token)
    }
  },

  getters: {
    isAuthenticated: state => Boolean(state.currentUser),
    currentUserId: state => state.currentUser?.id,
    isImpersonating: state => Boolean(state.currentUser?.impersonator)
  }
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSessionStore, import.meta.hot));
}
