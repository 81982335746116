<template>
  <div>
    <div class="fixed z-1 top-8 sm:top-1/4 left-1/2 -translate-x-1/2 px-8 w-full sm:max-w-2xl flex flex-col gap-3 text-center">
      <p>
        <u-icon class="text-8xl text-heroblue" :name="errorMessage.icon" />
      </p>
      <h1 class="font-serif text-3xl text-charcoal">{{errorMessage.title}}</h1>
      <p class="text-charcoal-400 text-xl">{{errorMessage.content}}</p>
      <p>
        <u-button @click="clearError({redirect: '/gallery'})" size="xl" variant="link" class="underline">Back to FOREVER.com</u-button>
      </p>
      <div v-if="!isProduction" class="flex justify-center">

        <u-button @click="viewErrorDetailsModal" size="sm" color="red">View Error Details</u-button>
      </div>
    </div>

    <div class="fixed w-full sm:max-w-3xl px-8 bottom-8 left-1/2 -translate-x-1/2 text-center text-charcoal-400">
      <p>Need to contact customer support?<br />Send us an email at <a href="mailto:support@forever.com">support@forever.com</a> or call us at <a href="tel: 1-888-367-3837">1-888-FOREVER (1-888-367-3837)</a></p>
    </div>

    <core-modal-modals />

  </div>
</template>

<script setup>
  import {LayoutModalErrorDetails} from '#components';

  const errors = {
    403: {
      title: 'Permission Denied (403)',
      content: 'Sorry, you do not have access to the requested page.',
      icon: COMMON_ICONS.information
    },
    404: {
      title: 'Oops. Page Not Found (404)',
      content: `Sorry, the page you were looking for doesn't exist. You may have mistyped the address, or the page may have moved.`,
      icon: 'i-ri-emotion-sad-line'
    },
    generic: {
      title: 'Sorry. Something Went Wrong',
      content: `We've had a hiccup on our end, please try again later. We'll make sure it gets fixed.`,
      icon: 'i-ri-emotion-sad-line'
    }
  };

  const props = defineProps({
    error: Object
  });

  const errorMessage = computed(() => errors[props.error.statusCode] || errors.generic)
  const isProduction = computed(() => useRuntimeConfig().public.isProduction);

  useHead({
    bodyAttrs: {
      class: 'bg-blue-50 bg-opacity-25 dark:bg-opacity-100'
    }
  });

  useForeverHead({
    title: errorMessage.value.title
  });

  function viewErrorDetailsModal() {
    useFModal({
      id: 'error-details-modal',
      component: LayoutModalErrorDetails,
      size: 'lg',
      props: {
        error: props.error
      }
    }).open();
  }

  onMounted(() => {

    //note: special error handling for 500 errors, since they could be coming from the nitro server and are not captured in the error-handlers plugin
    if (props.error.statusCode === 500) {
      useErrorPost().report({error: props.error});
    }

  });

  defineShortcuts({
    'meta_e': viewErrorDetailsModal
  });
</script>
