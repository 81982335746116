import cloneDeep from 'lodash.clonedeep';
import {AUTH_COOKIE_NAME} from '~/conf/app.js';

const createApi = baseURL => ({
  buildPathAndQs({path, params}) {
    let url = path;

    if (params?.route) {
      url = buildRouteParams(url, params.route);
    }

    if (params?.query) {
      url = `${url}${buildQueryString(params.query)}`;
    }

    return url;
  },
  getFullUrl({path, params}){
    return `${baseURL}${this.buildPathAndQs({path, params})}`;
  },

  fetch(path, options = {}) {
    const useOptions = cloneDeep(options);
    const {params} = useOptions;
    const url = this.buildPathAndQs({path, params});

    delete useOptions.params;

    return $fetch(url, {
      baseURL,
      headers: {
        ...(useCookie(AUTH_COOKIE_NAME).value ? {'X-Forever-Authorization': useCookie(AUTH_COOKIE_NAME).value} : {})
      },
      ...useOptions
    });
  },

  post(path, options) {
    return this.fetch(path, {
      method: 'POST',
      ...options
    });
  },
  put(path, options) {
    return this.fetch(path, {
      method: 'PUT',
      ...options
    });
  },
  delete(path, options) {
    return this.fetch(path, {
      method: 'DELETE',
      ...options
    });
  }
});

export const useLocalApi = () => {
  return createApi('/app/api');
}

export const useMarsApiV3 = () => {
  const runtimeConfig = useRuntimeConfig();

  return createApi(
    runtimeConfig.public.apiCoreHost
      ? `${runtimeConfig.public.apiCoreHost}/api/v3`
      : '/core/api/v3'
  );
};

export const useMarsApiV4 = () => {
  const runtimeConfig = useRuntimeConfig();

  return createApi(
    runtimeConfig.public.apiCoreHost
      ? `${runtimeConfig.public.apiCoreHost}/api/v4`
      : '/core/api/v4'
  );
};

export const useStoreApi = () => {
  const suffix = 'api/v1';
  const runtimeConfig = useRuntimeConfig();
  let url = ''

  if (process.server && runtimeConfig.public.apiStoreHostServer) {
    url = `${runtimeConfig.public.apiStoreHostServer}/${suffix}`;
  } else {
    url = runtimeConfig.public.apiStoreHost
      ? `${runtimeConfig.public.apiStoreHost}/${suffix}`
      : `/store/${suffix}`;
  }

  return createApi(url);
};

export const useJupiterApi = () => {
  const runtimeConfig = useRuntimeConfig();

  return createApi(
    runtimeConfig.public.apiJupiterHost
      ? `${runtimeConfig.public.apiJupiterHost}/api/v1`
      : '/jupiter/api/v1'
  );
};

export const useNotificationsApi = () => {
  const runtimeConfig = useRuntimeConfig();

  return createApi(
    runtimeConfig.public.apiNotificationsHost
      ? `${runtimeConfig.public.apiNotificationsHost}/api/v1`
      : '/notification5/api/v1'
  );
};

/*export const useDownloadsApi = () => {
  const runtimeConfig = useRuntimeConfig();

  return createApi(
    runtimeConfig.public.downloadsHost
      ? `${runtimeConfig.public.downloadsHost}/api/v3`
      : '/notifications/api/v3'
  );
};*/

