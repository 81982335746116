import {defineStore} from 'pinia';

export const useNotificationStore = defineStore('notifications', {
  state: () => ({
    notifications: null,
    hasMore: false,
    cursor: null,

    newCount: 0
  }),

  actions: {
    async getNotifications({nextPage}) {
      if (!nextPage) {
        this.resetNotifications();
      }

      const res = await useNotificationsApiService().fetchNotifications({cursor: this.cursor});

      this.notifications = [...(this.notifications || []), ...res.data];
      this.hasMore = res.data?.length === res.meta.per_page;
      this.cursor = res.links.next;

      return this.notifications;
    },

    async getNewCount() {
      this.newCount = (await useNotificationsApiService().fetchCounts())?.new;
    },

    async markNotificationAsUnread(notification) {
      return await this.updateNotificationStatus({
        id: notification.id,
        status: NOTIFICATION_STATES.VIEWED
      });
    },

    async markNotificationAsRead(notification) {
      return await this.updateNotificationStatus({
        id: notification.id,
        status: NOTIFICATION_STATES.READ
      });
    },

    async updateNotificationStatus({id, status}) {
      await useNotificationsApiService().updateNotificationStatus({
        id,
        status
      });

      this.notifications.forEach((n) => {
        if (n.id === id) {
          n.status = status;
        }
      });
    },

    async markAllRead() {
      await useNotificationsApiService().markAllNotificationsRead();

      this.notifications.forEach((n) => {
        n.status = NOTIFICATION_STATES.READ;
      });
    },

    async deleteNotification(notification) {
      await useNotificationsApiService().deleteNotification(notification.id);

      this.notifications.forEach((n, i) => {
        if (n.id === notification.id) {
          this.notifications.splice(i, 1);
        }
      });
    },

    resetNotificationCount() {
      this.newCount = 0;
    },

    resetNotifications() {
      this.notifications = null;
      this.hasMore = false;
      this.cursor = null;
    }
  },

  getters: {}
});
