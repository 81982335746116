export const FRIEND_STATUS = {
  none: 'none',
  completed: 'completed',
  pending: 'pending',
  sent: 'sent'
};

//not to be confused with "role" which is a user's role in the system, or membership which indicated a storage purchase
export const USER_ACCOUNT_TYPES = {
  unspecified: {
    value: 'unspecified',
    label: 'Unspecified'
  },
  person: {
    value: 'person',
    label: 'Person'
  },
  organization: {
    value: 'organization',
    label: 'Organization'
  }
};

export const USER_GENDERS = {
  male: {
    value: 'male',
    label: 'Male'
  },
  female: {
    value: 'female',
    label: 'Female'
  },
  unspecified: {
    value: 'unspecified',
    label: 'Unspecified'
  }
}

export const USER_ORGANIZATION_STATUSES = {
  active: {
    value: 'active',
    label: 'Active'
  },
  inactive: {
    value: 'inactive',
    label: 'Inactive'
  }
}

export const USER_ABILITIES = {
  transcodeVideo: 'transcode_video',
  streamVideo: 'stream_video',
  accessP2p:  'p2p_membership',
  accessP2pEditor: 'p2p_editor',
  facialRecognition: 'facial_recognition',
  facialRecognitionLifetime: 'lifetime_facial_recognition',
  premiumFeatures: 'premium_features' //todo, remove when possible face rec is added to abilities
};

export const USER_LIST_CONTAINER_CLASS = 'grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6';
export const USER_LIST_SECTION_SIZE = 60;
