const apiPaths = {
  signIn: 'sign_in',
  currentUser: '/current_user',
  deleteBinSummary: '/deleted_files/count',
  impersonations: '/impersonations'
};

const storeApiPaths = {
  userStoreInfo: '/shared-header'
}

export function useSessionApi() {
  return {
    async logIn({email, password}) {
      const data = await useMarsApiV3().post(
        apiPaths.signIn,
        {
          body: {
            email,
            password
          }
        }
      );
      return data.user;
    },

    async getCurrentUser() {
      const data = await useMarsApiV3().fetch(apiPaths.currentUser);
      return data.user;
    },

    async getUserStoreInfo() {
      return await useStoreApi().fetch(storeApiPaths.userStoreInfo);
    },

    async getDeleteBinSummary() {
      return await useMarsApiV3().fetch(apiPaths.deleteBinSummary);
    },

    async updateCurrentUser(user) {
      const data = await useMarsApiV3().put(
        apiPaths.currentUser,
        {
          body: {user}
        }
      );

      return data.user;
    },

    async startImpersonate({id}) {
      const res = await useMarsApiV3().post(
        apiPaths.impersonations,
        {
          body: {id}
        }
      );

      return res.user;
    },

    async stopImpersonate() {
      const res = await useMarsApiV3().fetch(
        apiPaths.impersonations,
        {method: 'DELETE'}
      );

      return res.user;
    }

  };
}
