import cloneDeep from 'lodash.clonedeep';

/**
 * Maps a store user object into a decorated user object for use in Mars UI components.
 * @param initialUser
 * @returns {*}
 */
export function mapCurrentUser(initialUser) {
  const user = cloneDeep(initialUser);

  user.isMapped = true;

  //remove spaces from friendly storage sizes
  user.storage_capacity_gb = user.storage_capacity_gb.replace(/\s/g, '')
  user.storage_used_gb = user.storage_used_gb.replace(/\s/g, '')
  user.storage_remaining_gb = user.storage_remaining_gb.replace(/\s/g, '')

  user.isMember = user.membership;
  user.isAuthenticated = user.role;
  user.isGuest = user.role.toLowerCase().includes('guest');
  user.isImpersonator = user.impersonator;
  user.storagePercentUsed = user.storage_capacity
    ? Math.floor((user.storage_used / user.storage_capacity) * 100)
    : 0;
  user.isStorageFull = user.storage_used >= user.storage_capacity;
  user.isStorageNearFull = user.storagePercentUsed >= 80 && user.storage_used < user.storage_capacity;
  user.isNearOrAtCapacity = user.storagePercentUsed >= 80;
  user.totalUserFiles = user.files_count;
  user.capacityLevel = (() => user.isStorageFull ? 'red' : user.isStorageNearFull ? 'orange': 'green')();

  user.isAdmin = user.role === 'admin' || user.isImpersonator && user.impersonator.role === 'admin';

  user.isOrganization = user.account_type === USER_ACCOUNT_TYPES.organization.value;
  user.isPerson = user.account_type === USER_ACCOUNT_TYPES.person.value;

  //determines whether user goes to d4d or d4c
  user.isDesignerAdmin = !user.isImpersonator && user.isAdmin;

  user.can = {
    transcodeVideo: user.abilities?.includes(USER_ABILITIES.transcodeVideo),
    streamVideo: user.abilities?.includes(USER_ABILITIES.streamVideo),
    accessP2p: user.abilities?.includes(USER_ABILITIES.accessP2p),
    accessP2pEditor: user.abilities?.includes(USER_ABILITIES.accessP2pEditor),
    recognizeFaces: user.abilities?.includes(USER_ABILITIES.facialRecognition) || user.abilities?.includes(USER_ABILITIES.facialRecognitionLifetime) || user.abilities?.includes(USER_ABILITIES.premiumFeatures),
  };

  user.hasAmbassador = !!user.ambassador_id;

  user.profileClassName = getProfileClassNameFromUUID(user.id);
  user.avatarImage = user.images?.medium?.url

  return user;
}
