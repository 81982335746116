<template>
  <div v-for="modal in modals" :key="modal.id" :id="`modal-${modal.id}`">

    <transition name="backdrop">
      <div
        v-if="modal.isOpen"
        key="backdrop"
        class="fixed inset-0 z-50"
        :class="modal.backdropClass"
      />
    </transition>

    <div class="fixed flex justify-center items-start z-50 overflow-y-auto scrollbar-dark" :class="modal.isFullScreen ? 'inset-0' : 'p-1 sm:p-4 md:pt-6 lg:pt-10 inset-1'" @click.stop.self="() => modal.clickToClose ? modal.close() : null">
      <transition name="dialog">
        <div v-if="modal.isOpen" class="z-50 pointer-events-auto relative" :class="[modal.sizeClass, modal.bgClass, modal.overflowClass, {'rounded shadow': !modal.isFullScreen}]">
          <u-button
            v-if="modal.showClose"
            color="gray"
            variant="ghost"
            :icon="COMMON_ICONS.close"
            class="absolute right-4 top-3 leading-none z-10"
            @click="modal.close()"
          />
          <component :is="modal.component" v-bind="modal.props" :modal="modal" v-on="modal.eventHandlers || {}" v-if="modal.component" />
        </div>
      </transition>
    </div>

  </div>
</template>

<script setup>
  const {modals, clear, closeTopModal, getTopModal} = useFModals();
  const {beforeEach} = useRouter();

  function closeTop(e) {
    const usingInput = !!(e.srcElement.tagName === "INPUT" || e.srcElement.tagName === "TEXTAREA" || e.srcElement.contentEditable === "true" || e.srcElement.contentEditable === "plaintext-only");

    if (e.key === 'Escape' && !usingInput && modals.value.length && getTopModal().escToClose) {
      closeTopModal();
      e.stopPropagation();
    }
  }

  beforeEach(() => {
    clear();
  });

  onMounted(() => {
    document.addEventListener('keyup', closeTop, {capture: true});
  });

  onUnmounted(() => {
    document.removeEventListener('keyup', closeTop);
  })

</script>

<style>
  .backdrop-enter-active,
  .backdrop-leave-active {
    @apply transition-opacity duration-200;
  }

  .backdrop-enter-from,
  .backdrop-leave-to {
    @apply opacity-0;
  }

  .dialog-enter-active,
  .dialog-leave-active {
    @apply transition duration-200;
  }

  .dialog-enter-from,
  .dialog-leave-to {
    @apply opacity-0 scale-75
  }
</style>
