/**
 * @description
 * Generates a new, random UUID.
 *
 * @returns {string} The generated UUID
 */
export function generateUUID() {
  let d = new Date().getTime();
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (d + Math.random() * 16) %  16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}

/**
 *
 * @param uuid
 * @returns {string}
 */
export function getProfileClassNameFromUUID(uuid) {
  const classes = [
    'bg-artisan-700',
    'bg-historian-700',
    'bg-services-700',
    'bg-print-700',
    'bg-ambassador',
    'bg-ambassador-700',
    'bg-p2p',
    'bg-green-700',
    'bg-blue-700',
    'bg-services-700' //used to be accent, but they were pretty much same.
  ];
  const index = parseInt(uuid.split('-')[0], 16) % classes.length;
  return classes[index];
}

export function getCopyName(name, {lengthLimit = 255, addText = ' - Copy', useEllipsis = true} = {}) {
  const ellipsis = useEllipsis ? '...' : '';

  if (name.length + addText.length > lengthLimit) {
    return `${name.slice(0, lengthLimit - addText.length - ellipsis.length)}${ellipsis}${addText}`;
  }

  return `${name}${addText}`;
}
