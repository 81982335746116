export const NOTIFICATION_STATES = {
  NEW: 'new',
  VIEWED: 'viewed',
  READ: 'read',
  DISMISSED: 'dismissed'
};

export const NOTIFICATION_SUBTYPES = {
  friendRequest: 'new_friend_request',
  friendRequestAccepted: 'accepted_friend_request',
  madeAccountManager: 'made_account_manager',
  uploadReminder: 'upload_reminder',
  uploadComplete: 'upload_complete',
  friendAlbumFilesAdded: 'friend_album_files_added',
  orderShipped: 'order_shipped',
  exifDownloadReady: 'exif_download_ready'
};
