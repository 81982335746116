import imageFavicon from '~/assets/images/shared/favicon.png';
import imageFaviconLg from '~/assets/images/shared/favicon-180x180.png';
import ogDefault from '~/assets/images/shared/og-fallback-image.jpg';

/**
 * @note
 * We are not concerned with anything in her being reactive with exception of the page title. Everything else is for the
 * benefit of spiders and scrapers, and they only watch a page on load.
 */
export function useForeverHead({title = '', description, socialImage, overrideTitle} = {}) {
  const finalDescription = description || 'A permanent digital home for your memories.';
  const finalSocialImage = socialImage || ogDefault;

  const titleTemplate = titleRefOrVal => {
    const titleChunk = titleRefOrVal.value || titleRefOrVal;
    let finalTitle;

    if (overrideTitle) {
      finalTitle = titleChunk;
    } else {
      finalTitle = titleChunk
        ? `${titleChunk} | FOREVER.com`
        : `FOREVER.com`;
    }

    return finalTitle;
  };

  useHead({
    titleTemplate,

    title,

    htmlAttrs: {
      lang: 'en'
    },

    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1' },
      { 'http-equiv': 'pragma', content: 'no-store' },
      { 'http-equiv': 'cache-control', content: 'no-store' },
      { 'http-equiv': 'expires', content: '0' },
      { hid: 'description', name: 'description', content: finalDescription},
      { hid: 'twitter:title', name: 'twitter:title', content: titleTemplate(title) },
      { hid: 'twitter:description', name: 'twitter:description', content: finalDescription },
      { hid: 'twitter:image', name: 'twitter:image', content: finalSocialImage },
      { hid: 'twitter:image:alt', name: 'twitter:image:alt', content: titleTemplate(title) },
      { hid: 'og:type', name: 'og:type', content: 'website'},
      { hid: 'og:site_name', name: 'og:site_name', content: 'FOREVER.com'},
      { hid: 'og:title', property: 'og:title', content: titleTemplate(title) },
      { hid: 'og:description', property: 'og:description', content: finalDescription },
      { hid: 'og:image', property: 'og:image', content: finalSocialImage },
      { hid: 'og:image:secure_url', property: 'og:image:secure_url', content: finalSocialImage },
      { hid: 'og:image:alt', property: 'og:image:alt', content: titleTemplate(title) },

      //todo: optional based on context below. Do we need?
      /*{ hid: 'og:image:width', property: 'og:image:width', content: 0 },
      { hid: 'og:image:height', property: 'og:image:height', content: 0 },*/

    ],

    link: [
      {
        rel: 'icon',
        type: 'image/x-icon',
        href: imageFavicon
      },
      {
        rel: 'apple-touch-icon',
        type: 'image/png',
        href: imageFaviconLg
      }
    ]
  });
}



