const apiPaths = {
  promotionPricingAll: '/groups/forever-print-artisan-products/primary_promotion',
  group: '/groups/:slug/marketing',
  groupPromotions: '/groups/:slug/primary_promotion',
};

export function usePricingApi() {
  return {
    async getPromotionPricing() {
      const data = await useStoreApi().fetch(apiPaths.promotionPricingAll);
      const promotions  = data.group?.products;

      return promotions?.filter(item => item.primary_promotion && Object.keys(item.primary_promotion).length);
    },

    async getGroupProducts({groupSlug}) {
      const data = await useStoreApi().fetch(apiPaths.group, {
        params: {
          route: {slug: groupSlug},
          query: {
            //include_descriptions: false
          }
        }
      });
      return data.group?.products;
    },

    async getGroupPromotions({groupSlug}) {
      const data = await useStoreApi().fetch(apiPaths.groupPromotions, {
        params: {
          route: {slug: groupSlug}
        }
      });
      return data.group?.products;
    }
  }
}
