const apiPaths = {
  notifications: '/notifications',
  counts: '/notifications/counts',
  markAllRead: '/notifications/mark_all_read',
  notification: '/notifications/:id'
};

export function useNotificationsApiService() {

  async function fetchNotifications({cursor}) {
    const options = {
      params: {
        query: {cursor}
      }
    };

    const res = await useNotificationsApi().fetch(apiPaths.notifications, options);

    if (res.data?.length) {
      res.data = res.data.map(notificationMapper);
    }

    return res;
  }

  async function fetchCounts({status = 'new'} = {}) {
    const options = {
      params: {
        query: {status}
      }
    };

    const res = await useNotificationsApi().fetch(apiPaths.counts, options);
    return res.counts;
  }

  async function updateNotificationStatus({id, status}) {
    return await useNotificationsApi().fetch(apiPaths.notification, {
      method: 'PUT',
      params: {
        route: {id}
      },
      body: {
        notification: {status}
      }
    });
  }

  async function markAllNotificationsRead() {
    return await useNotificationsApi().fetch(apiPaths.markAllRead, {method: 'POST'})
  }

  async function deleteNotification(id) {
    const params = {
      route: {id}
    };

    return await useNotificationsApi().fetch(apiPaths.notification, {method: 'DELETE', params});
  }

  return {
    fetchNotifications,
    fetchCounts,
    updateNotificationStatus,
    markAllNotificationsRead,
    deleteNotification
  };
}
