/**
 * global error handling
 */

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('vue:error', async (error, instance, info) => {
    useErrorPost().report({error, instance, info});
  });
});


